<template>
    <div class="form-list">
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <div class="h-b"><el-button type="primary" size="small" @click="add">新增组件</el-button></div>
                <div class="h-b"><el-button type="primary" size="small" @click="del">删除组件</el-button></div>
            </div>
        </div>
        <el-table ref="multipleTable" :data="dataSource"  @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="33">
            </el-table-column>
            <el-table-column label="商品编码" >
                <template slot-scope="scope">
                    <span  v-if="!scope.row.RowEdit" v-model="scope.row.ProductNo">{{scope.row.ProductNo}}</span>
                    <el-input v-if="scope.row.RowEdit" type="text" suffix-icon="el-icon-search" v-model="scope.row.ProductNo" @click.native="scope.row.RowEdit && handleIconClick(scope.row)"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="商品名称">
                <template slot-scope="scope">
                    <span  v-if="!scope.row.RowEdit" v-model="scope.row.ProductName">{{scope.row.ProductName}}</span>
                    <el-input v-if="scope.row.RowEdit" type="text" suffix-icon="el-icon-search"  v-model="scope.row.ProductName" @click.native="scope.row.RowEdit &&handleIconClick(scope.row)"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="规格" prop="Specification">
            </el-table-column>
            <el-table-column label="数量" >
                <template slot-scope="scope">
                    <input v-if="scope.row.RowEdit"  placeholder="数量" style="width:60px;" v-model="scope.row.ProductNumber" maxlength="8" minlength="1" type="number" />
                    <span v-if="!scope.row.RowEdit" v-model="scope.row.ProductNumber">{{scope.row.ProductNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column label="排序" >
                <template slot-scope="scope">
                    <input placeholder="排序" v-if="scope.row.RowEdit" style="width:60px;"  v-model="scope.row.DisplayOrder" maxlength="3" minlength="1" type="number" /> 
                    <span v-if="!scope.row.RowEdit" v-model="scope.row.DisplayOrder">{{scope.row.DisplayOrder}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" >
                <template slot-scope="scope">
                    <el-button type="text" size="small" v-show="!scope.row.RowEdit" @click="edit(scope.row)">编辑</el-button>
                    <el-button type="text" size="small" v-show="scope.row.RowEdit" @click="ok(scope.row)">确认</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!--选择商品-->
        <DialogEx :options="bomSkuOptions" title="选择商品" style="width:70%;margin-left:15%;"  @onOk="onBomSkuOk" >
            <BomSku @onSelectedRow="onSkuSelectedRow"></BomSku>
        </DialogEx>
    </div>
</template>
<script>
    export default {
        data() {
          return {
            multipleSelection: [],
            bomSkuOptions:{
                visible:false,
                size:'large'
            },
            currenSelectedRow:{},
            bomSkuDataSource: {},
            currenSkuSelectedRow: {}
          }
        },
        props:{
            dataSource:{},
        },
        methods: {
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            handleIconClick:function(row){
                this.bomSkuOptions.visible=true;
                this.currenSelectedRow = row;
            },
            onSkuSelectedRow: function (item) {
                this.currenSkuSelectedRow = item;
            },
            edit: function (row) {
                row.RowEdit = true;
                row.Tag = this.Utils.cloneObj(row);
            },
            ok:function(row) {
                if (row.ProductNo.length <= 0) {
                    this.Utils.messageBox("请选择组件商品", "error");
                    return;
                }
                if (row.ProductNumber.length <= 0) {
                    this.Utils.messageBox("数量不能为空", "error");
                    return;
                }
                if (row.ProductNumber <= 0) {
                    this.Utils.messageBox("数量不能小于0", "error");
                    return;
                }
                if (row.DisplayOrder.length <= 0) {
                    this.Utils.messageBox("排序不能为空", "error");
                    return;
                }
                row.RowEdit = false
            },
            add: function () {
                this.$ajax.send("omsapi/productbom/childempty", "get", {}, data => {
                    data.Result.RowEdit = true;
                    data.Result.Tag = this.Utils.cloneObj(data.Result);
                    this.dataSource.push(data.Result);
                });
            },
            del:function(){
                 this.multipleSelection.length == 0 &&
                 (this.Utils.messageBox("未选择删除的组件.", "info")) ||
                 this.multipleSelection.forEach(m => this.dataSource.remove(m));
            },
            onBomSkuOk() {
                if (!this.currenSkuSelectedRow) {
                    this.Utils.messageBox("请选择一个商品.", "error");
                    this.bomSkuOptions.visible = true;
                    return false;
                }
                else
                    this.bomSkuOptions.visible = false;
                if (this.currenSkuSelectedRow && this.currenSkuSelectedRow.ProductNo) {
                    //判断是否重复
                    if (this.dataSource.length > 0) {
                        var count = 0;
                        for (let i = 0; i < this.dataSource.length; i++) {
                            var row = this.dataSource[i];
                            if (row.ProductNo != this.currenSkuSelectedRow.ProductNo) continue;
                            count++;
                        }
                        if (count > 0) {
                            this.Utils.messageBox("不能重复添加相同的商品组件", "error");
                            return;
                        }
                    }
                    this.currenSelectedRow.ProductNo = this.currenSkuSelectedRow.ProductNo;
                    this.currenSelectedRow.ProductName = this.currenSkuSelectedRow.ProductName;
                    this.currenSelectedRow.SKU = this.currenSkuSelectedRow.SKU;
                    
                } else{
                    //alert(this.currenSkuSelectedRow)
                }
            }
        },
        components: {
            "BomSku": resolve => { require(['./bomsku.vue'], resolve) },
        }
  }
</script>
<style>

</style>